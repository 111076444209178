import { AnalyticsDashStateDataService as Analytics, TelemetryDashStateDataService as Telemetry, ConfigureDashStateDataService as Configure } from './OtherDashStateDataService';
import {
	SET_ANALYTICS_READY,
	SET_ANALYTICS_DATA,
	SET_ANALYTICS_DATA_READY,
	SET_IS_ANALYTICS_DATA_DIRTY,
	SET_ANALYTICS_LOADING,
	SET_TELEMETRY_UPDATE_TIME,
	SET_TELEMETRY_READY,
	SET_TELEMETRY_DATA,
	SET_TELEMETRY_DATA_READY,
	SET_TELE_DEFAULTS_DATA,
	SET_TELE_CUSTOMER_DATA,
	SET_TELE_DETAILED_DATA,
	SET_TELE_MAP_DEFAULT_MARKERS,
	SET_TELE_MAP_UPDATED_MARKERS,
	SET_TELE_MAP_INFO_WINDOWS,
	SET_IS_TELEMETRY_DATA_DIRTY,
	SET_TELEMETRY_LOADING,
	SET_CONFIGURE_UPDATE_TIME,
	SET_CONFIGURE_READY,
	SET_CONFIGURE_DATA,
	SET_CONFIGURE_DATA_READY,
	SET_IS_CONFIGURE_DATA_DIRTY,
	SET_CONFIGURE_LOADING,
} from './OtherDashStateMutations';
import _ from 'lodash';

const consoleLogTitle = '[OtherDashState.js] - ';

const otherHelpers = {
	formatNumbers: {
		getPrettyAndPercent: function (value1, value2) {
			const percentage = (value1 / value2) * 100;
			const numberPercent = parseInt(percentage);
			const textPercent = percentage.toFixed(0) + '%';
			const textPretty = value1 + '/' + value2;
			return { numberPercent: numberPercent, textPercent: textPercent, textPretty: textPretty };
		},
	},
	testJSON: function (text) {
		if (typeof text !== "string") {
			return false;
		}
		try {
			var json = JSON.parse(text);
			return (typeof json === 'object');
		}
		catch (error) {
			return false;
		}
	},
	isTimestamp: function (n) {
		return Number(n) ? new Date(Number(n)).getTime() > 0 : false;
	},
	dateDiff: {
		inMinutes: function (d1, d2) {
			var t2 = d2.getTime();
			var t1 = d1.getTime();

			return Math.floor((t2 - t1) / (1 * 60 * 1000));
		},
		inHours: function (d1, d2) {
			var t2 = d2.getTime();
			var t1 = d1.getTime();

			return Math.floor((t2 - t1) / (1 * 3600 * 1000));
		},
		inDays: function (d1, d2) {
			var t2 = d2.getTime();
			var t1 = d1.getTime();

			return Math.floor((t2 - t1) / (24 * 3600 * 1000));
		},
		inWeeks: function (d1, d2) {
			var t2 = d2.getTime();
			var t1 = d1.getTime();

			return parseInt((t2 - t1) / (24 * 3600 * 1000 * 7));
		},
		inMonths: function (d1, d2) {
			var d1Y = d1.getFullYear();
			var d2Y = d2.getFullYear();
			var d1M = d1.getMonth();
			var d2M = d2.getMonth();

			return d2M + 12 * d2Y - (d1M + 12 * d1Y);
		},
		inYears: function (d1, d2) {
			return d2.getFullYear() - d1.getFullYear();
		},
	},
	emptyString: function (value) {
		if (value == null || value.length === 0) {
			if (typeof value === 'array') {
				return [];
			} else if (typeof value === 'array') {
				return {};
			} else {
				return '';
			}
		} else {
			return value;
		}
	},
	defaultsClassArray: function (classString) {
		let classArray = false;
		if (Boolean(classString) !== false) {
			let cleanClasses = classString;
			if (cleanClasses.indexOf(',') !== -1) {
				cleanClasses = cleanClasses.replace(',', ' ');
			}
			cleanClasses = cleanClasses.replace(/\s\s+/g, ' ');
			let arrayClasses = cleanClasses.split(' ');
			if (arrayClasses.length > 0) {
				classArray = arrayClasses;
			}
		}
		return classArray;
	},
	buildList: {
		getTypeObj: function (totalCount, typeCount, kioskDefaults) {
			const typeObj = {
				'count': typeCount,
				'percent': '',
				'pretty': '',
				'status': 'success',
				'icon': 'success',
			};
			const calcPercent = otherHelpers.formatNumbers.getPrettyAndPercent(typeCount, totalCount);
			typeObj.percent = calcPercent.textPercent;
			typeObj.pretty = calcPercent.textPretty;
			typeObj.status = otherHelpers.buildList.getStatus(calcPercent, kioskDefaults);
			typeObj.icon = otherHelpers.buildList.getIcon(calcPercent, kioskDefaults);

			return typeObj;
		},
		getStatus: function (calcPercent, kioskDefaults) {
			const builtStatus =
				calcPercent.numberPercent < kioskDefaults.successThreshhold
					? calcPercent.numberPercent <= kioskDefaults.criticalThreshhold
						? kioskDefaults.criticalClasses
						: kioskDefaults.warningClasses
					: kioskDefaults.successClasses;
			return builtStatus;
		},
		getIcon: function (calcPercent, kioskDefaults) {
			const builtIcon =
				calcPercent.numberPercent < kioskDefaults.successThreshhold
					? calcPercent.numberPercent <= kioskDefaults.criticalThreshhold
						? 'danger'
						: 'warning'
					: 'success';
			return builtIcon;
		},
		getKioskCounts: function (detailData) {
			const kioskCounts = {
				totalKiosks: detailData.kiosk.total,
				activeKiosks: detailData.kiosk.active,
				recentKiosks: detailData.kiosk.recent,
				percentKiosks: detailData.kiosk.percent,
				prettyKiosks: detailData.kiosk.pretty,
				statusKiosks: detailData.kiosk.status,
				statusKiosksIcon: detailData.kiosk.icon,
				pwaCurrentVersion: detailData.pwa.version,
				pwaVersionKiosks: detailData.pwa.count,
				pwaVersionPercent: detailData.pwa.percent,
				pwaVersionPretty: detailData.pwa.pretty,
				pwaVersionStatus: detailData.pwa.status,
				pwaVersionStatusIcon: detailData.pwa.icon,
				cefCurrentVersion: detailData.cef.version,
				cefVersionKiosks: detailData.cef.count,
				cefVersionPercent: detailData.cef.percent,
				cefVersionPretty: detailData.cef.pretty,
				cefVersionStatus: detailData.cef.status,
				cefVersionStatusIcon: detailData.cef.icon,
				resolutionCounts: detailData.res.counts,
				resolutionPercent: detailData.res.percent,
				resolutionPretty: detailData.res.pretty,
				resolutionStatus: detailData.res.status,
				resolutionStatusIcon: detailData.res.icon,
				horizontalPercent: detailData.hoz.percent,
				horizontalPretty: detailData.hoz.pretty,
				verticalPercent: detailData.vrt.percent,
				verticalPretty: detailData.vrt.pretty,
				customerPercent: detailData.cst.percent,
				customerPretty: detailData.cst.pretty,
			};
			return kioskCounts;
		},
		kiosk: function (stateListOfKiosks, kioskDefaults, stateMapMarkers) {
			const filterListOfKiosks = {
				'allKiosks': {
					'ids': new Array(),
					'list': new Array(),
					'count': 0,
				},
				'badKiosks': {
					'ids': new Array(),
					'list': new Array(),
					'count': 0,
				},
				'goodKiosks': {
					'ids': new Array(),
					'list': new Array(),
					'count': 0,
				},
				"pwa": {
					'count': 0,
					'percent': '',
					'pretty': '',
					'status': 'success',
					'icon': 'success',
				},
				"pwaBadKiosks": {
					'ids': new Array(),
					'list': new Array(),
				},
				"pwaGoodKiosks": {
					'ids': new Array(),
					'list': new Array(),
				},
				"cef": {
					'count': 0,
					'percent': '',
					'pretty': '',
					'status': 'success',
					'icon': 'success',
				},
				"cefBadKiosks": {
					'ids': new Array(),
					'list': new Array(),
				},
				"cefGoodKiosks": {
					'ids': new Array(),
					'list': new Array(),
				},
				"res": {
					'count': 0,
					'counts': {},
					'percent': '',
					'pretty': '',
					'status': 'success',
					'icon': 'success',
				},
				"resBadKiosks": {
					'ids': new Array(),
					'list': new Array(),
				},
				"resGoodKiosks": {
					'ids': new Array(),
					'list': new Array(),
				},
				"hoz": {
					'count': 0,
					'percent': '',
					'pretty': '',
					'status': 'success',
					'icon': 'success',
				},
				"hozBadKiosks": {
					'ids': new Array(),
					'list': new Array(),
				},
				"hozGoodKiosks": {
					'ids': new Array(),
					'list': new Array(),
				},
				"vrt": {
					'count': 0,
					'percent': '',
					'pretty': '',
					'status': 'success',
					'icon': 'success',
				},
				"vrtBadKiosks": {
					'ids': new Array(),
					'list': new Array(),
				},
				"vrtGoodKiosks": {
					'ids': new Array(),
					'list': new Array(),
				},
				"cst": {
					'count': 0,
					'percent': '',
					'pretty': '',
					'status': 'success',
					'icon': 'success',
				},
				'otherKiosks': {
					'ids': new Array(),
					'list': new Array(),
					'count': 0,
				},
				'total': 0,
				'percent': '',
				'pretty': '',
				'status': 'success',
				'icon': 'success',
			};

			let resHorizontalCount = 0;
			let resVerticalCount = 0;
			let resOtherCount = 0;
			let accountArray = {};
			let accountKey = '';

			let totalCounter = 0;
			let kioskCounter = 0;
			const fullListOfKiosks = _.toArray(stateListOfKiosks);

			// Filter Kiosks and Non Kiosks (isKiosk === true)
			fullListOfKiosks.forEach((kioskItem) => {
				const kioskObj = JSON.parse(JSON.stringify(kioskItem));
				if (kioskObj.isKiosk === true || kioskObj.isKiosk === 'true') {
					const d1 = new Date(kioskObj.lastCheckIn);
					const d2 = new Date();
					const diffDays = otherHelpers.dateDiff.inDays(d1, d2);
					if (diffDays > 1) {
						filterListOfKiosks.badKiosks.ids.push(kioskObj.id);
						filterListOfKiosks.badKiosks.list.push(kioskObj);
					} else {
						filterListOfKiosks.goodKiosks.ids.push(kioskObj.id);
						filterListOfKiosks.goodKiosks.list.push(kioskObj);
					}
					if (kioskObj.pwaVersion === kioskDefaults['pwa-version'].version) {
						filterListOfKiosks.pwaGoodKiosks.ids.push(kioskObj.id);
						filterListOfKiosks.pwaGoodKiosks.list.push(kioskObj);
					} else {
						filterListOfKiosks.pwaBadKiosks.ids.push(kioskObj.id);
						filterListOfKiosks.pwaBadKiosks.list.push(kioskObj);
					}
					if (kioskObj.cefVersion === kioskDefaults['cef-version'].version) {
						filterListOfKiosks.cefGoodKiosks.ids.push(kioskObj.id);
						filterListOfKiosks.cefGoodKiosks.list.push(kioskObj);
					} else {
						filterListOfKiosks.cefBadKiosks.ids.push(kioskObj.id);
						filterListOfKiosks.cefBadKiosks.list.push(kioskObj);
					}

					let countedHorizontal = false;
					let countedVertical = false;
					if (kioskObj.vidRes === kioskDefaults['horizontal-resolution'].resolution) {
						countedHorizontal = true;
						resHorizontalCount++;
					}
					if (kioskObj.vidRes === kioskDefaults['vertical-resolution'].resolution) {
						countedVertical = true;
						resVerticalCount++;
					}
					if (countedHorizontal === true && countedVertical === false) {
						filterListOfKiosks.resGoodKiosks.ids.push(kioskObj.id);
						filterListOfKiosks.resGoodKiosks.list.push(kioskObj);
						filterListOfKiosks.hozGoodKiosks.ids.push(kioskObj.id);
						filterListOfKiosks.hozGoodKiosks.list.push(kioskObj);
						filterListOfKiosks.vrtBadKiosks.ids.push(kioskObj.id);
						filterListOfKiosks.vrtBadKiosks.list.push(kioskObj);
					}
					if (countedVertical === true && countedHorizontal === false) {
						filterListOfKiosks.resGoodKiosks.ids.push(kioskObj.id);
						filterListOfKiosks.resGoodKiosks.list.push(kioskObj);
						filterListOfKiosks.vrtGoodKiosks.ids.push(kioskObj.id);
						filterListOfKiosks.vrtGoodKiosks.list.push(kioskObj);
						filterListOfKiosks.hozBadKiosks.ids.push(kioskObj.id);
						filterListOfKiosks.hozBadKiosks.list.push(kioskObj);
					}
					if (countedHorizontal === false && countedVertical === false) {
						filterListOfKiosks.resBadKiosks.ids.push(kioskObj.id);
						filterListOfKiosks.resBadKiosks.list.push(kioskObj);
						filterListOfKiosks.hozBadKiosks.ids.push(kioskObj.id);
						filterListOfKiosks.hozBadKiosks.list.push(kioskObj);
						filterListOfKiosks.vrtBadKiosks.ids.push(kioskObj.id);
						filterListOfKiosks.vrtBadKiosks.list.push(kioskObj);
						resOtherCount++;
					}
					accountKey = String(kioskObj.accNum);
					accountArray[accountKey] = kioskObj;

					if (accountArray[accountKey].hasOwnProperty('accNum')) {
						console.log("Account Array Exist");
						console.log(accountArray[accountKey]);
						console.log("Account Array Updated");
						console.log(kioskObj);
					}

					// isKiosk is true
					filterListOfKiosks.allKiosks.ids.push(kioskObj.id);
					filterListOfKiosks.allKiosks.list.push(kioskObj);
					kioskCounter++;
				} else {
					// isKiosk is false
					filterListOfKiosks.otherKiosks.ids.push(kioskObj.id);
					filterListOfKiosks.otherKiosks.list.push(kioskObj);
				}
				totalCounter++;
			});

			console.log("All Kiosk Counter");
			console.log(filterListOfKiosks.allKiosks.list.length);

			console.log("Non Kiosk Counter");
			console.log(filterListOfKiosks.otherKiosks.list.length);

			filterListOfKiosks.allKiosks.count = filterListOfKiosks.allKiosks.list.length;
			filterListOfKiosks.badKiosks.count = filterListOfKiosks.badKiosks.list.length;
			filterListOfKiosks.goodKiosks.count = filterListOfKiosks.goodKiosks.list.length;
			filterListOfKiosks.otherKiosks.count = filterListOfKiosks.otherKiosks.list.length;
			filterListOfKiosks.total = totalCounter;

			// Log Active/Inactive Kiosks
			console.log("Active Kiosks count:" + filterListOfKiosks.goodKiosks.count);
			console.log("Active Kiosk List:");
			// Get list -> lastCheckIn
			let activeLastCheckInList = filterListOfKiosks.goodKiosks.list.map((kiosk) => {
				return {
					'accName': kiosk.accName,
					'accNum': kiosk.accNum,
					'lastCheckIn': kiosk.lastCheckIn,
				};
			})
			console.log(activeLastCheckInList);

			console.log("Inactive Kiosks count:" + filterListOfKiosks.badKiosks.count);
			console.log("Inactive Kiosk List:");
			let inactiveLastCheckInList = filterListOfKiosks.badKiosks.list.map((kiosk) => {
				return {
					'accName': kiosk.accName,
					'accNum': kiosk.accNum,
					'lastCheckIn': kiosk.lastCheckIn,
				};
			})
			console.log(inactiveLastCheckInList);
			// -------------------------

			const calcKiosksPercent = otherHelpers.formatNumbers.getPrettyAndPercent(filterListOfKiosks.goodKiosks.count, kioskCounter);
			filterListOfKiosks.percent = calcKiosksPercent.textPercent;
			filterListOfKiosks.pretty = calcKiosksPercent.textPretty;

			filterListOfKiosks.status = otherHelpers.buildList.getStatus(calcKiosksPercent, kioskDefaults['active-kiosks']);
			filterListOfKiosks.icon = otherHelpers.buildList.getIcon(calcKiosksPercent, kioskDefaults['active-kiosks']);

			const pwaObj = otherHelpers.buildList.getTypeObj(kioskCounter, filterListOfKiosks.pwaGoodKiosks.list.length, kioskDefaults['pwa-version']);
			filterListOfKiosks.pwa = { ...pwaObj };

			const cefObj = otherHelpers.buildList.getTypeObj(kioskCounter, filterListOfKiosks.cefGoodKiosks.list.length, kioskDefaults['cef-version']);
			filterListOfKiosks.cef = { ...cefObj };

			const resDefaults = JSON.parse(JSON.stringify(kioskDefaults['horizontal-resolution']));
			resDefaults.resolution = '';
			resDefaults.successThreshhold = Math.round(
				Number((kioskDefaults['horizontal-resolution'].successThreshhold + kioskDefaults['vertical-resolution'].successThreshhold) / 2).toFixed(0)
			);
			resDefaults.successThreshhold = Math.round(
				Number((kioskDefaults['horizontal-resolution'].criticalThreshhold + kioskDefaults['vertical-resolution'].criticalThreshhold) / 2).toFixed(0)
			);
			const resCount = resHorizontalCount + resVerticalCount;
			const resObj = otherHelpers.buildList.getTypeObj(kioskCounter, resCount, resDefaults);
			const resCounts = {
				'percent': resObj.percent,
				'pretty': resObj.pretty,
				'horizontal': resHorizontalCount,
				'vertical': resVerticalCount,
				'other': resOtherCount,
			};
			filterListOfKiosks.res = {
				...resObj,
				counts: {
					...resCounts,
				},
			};

			const hozObj = otherHelpers.buildList.getTypeObj(kioskCounter, filterListOfKiosks.hozGoodKiosks.list.length, kioskDefaults['horizontal-resolution']);
			filterListOfKiosks.hoz = { ...hozObj };

			const vrtObj = otherHelpers.buildList.getTypeObj(kioskCounter, filterListOfKiosks.vrtGoodKiosks.list.length, kioskDefaults['vertical-resolution']);
			filterListOfKiosks.vrt = { ...vrtObj };

			const cstObj = otherHelpers.buildList.getTypeObj(kioskCounter, _.toArray(accountArray).length, kioskDefaults['pwa-version']);
			filterListOfKiosks.cst = { ...cstObj };
			console.log("Unique Customers - CST Object");
			console.log(cstObj);
			console.log("Kiosk Counter: " + kioskCounter); // Count of all kiosks (including non-kiosks)
			console.log("Account Array: " + _.toArray(accountArray).length); // Count of Kiosk accounts (isKiosk = true, Unique)

			//filterListOfKiosks.activeMarkers = Object.fromEntries(Object.entries(mapMarkers).filter(([key, val]) => filterListOfKiosks.allKiosks.ids.includes(val.id)));
			//filterListOfKiosks.checkInMarkers.good = Object.fromEntries(Object.entries(mapMarkers).filter(([key, val]) => filterListOfKiosks.goodKiosks.ids.includes(val.id)));
			//filterListOfKiosks.checkInMarkers.bad = Object.fromEntries(Object.entries(mapMarkers).filter(([key, val]) => filterListOfKiosks.badKiosks.ids.includes(val.id)));

			const finalListOfKiosks = {
				'kiosk': {
					'total': filterListOfKiosks.total,
					'active': filterListOfKiosks.allKiosks.count,
					'recent': filterListOfKiosks.goodKiosks.count,
					'percent': filterListOfKiosks.percent,
					'pretty': filterListOfKiosks.pretty,
					'status': filterListOfKiosks.status,
					'icon': filterListOfKiosks.icon,
				},
				'pwa': {
					'version': 'PWA Version (' + kioskDefaults['pwa-version'].version + ')',
					'count': filterListOfKiosks.pwa.count,
					'percent': filterListOfKiosks.pwa.percent,
					'pretty': filterListOfKiosks.pwa.pretty,
					'status': filterListOfKiosks.pwa.status,
					'icon': filterListOfKiosks.pwa.icon,
				},
				'cef': {
					'version': 'CEF Version (' + kioskDefaults['cef-version'].version + ')',
					'count': filterListOfKiosks.cef.count,
					'percent': filterListOfKiosks.cef.percent,
					'pretty': filterListOfKiosks.cef.pretty,
					'status': filterListOfKiosks.cef.status,
					'icon': filterListOfKiosks.cef.icon,
				},
				'res': {
					'count': filterListOfKiosks.res.count,
					'counts': filterListOfKiosks.res.counts,
					'percent': filterListOfKiosks.res.percent,
					'pretty': filterListOfKiosks.res.pretty,
					'status': filterListOfKiosks.res.status,
					'icon': filterListOfKiosks.res.icon,
				},
				'hoz': {
					'percent': filterListOfKiosks.hoz.percent,
					'pretty': filterListOfKiosks.hoz.pretty,
				},
				'vrt': {
					'percent': filterListOfKiosks.vrt.percent,
					'pretty': filterListOfKiosks.vrt.pretty,
				},
				'cst': {
					'percent': filterListOfKiosks.cst.percent,
					'pretty': filterListOfKiosks.cst.pretty,
				},
				'markers': {
					'act': new Array(),
					'chk': {
						'bad': new Array(),
						'good': new Array(),
					},
					'pwa': {
						'bad': new Array(),
						'good': new Array(),
					},
					'cef': {
						'bad': new Array(),
						'good': new Array(),
					},
					'res': {
						'bad': new Array(),
						'good': new Array(),
					},
					'hoz': {
						'bad': new Array(),
						'good': new Array(),
					},
					'vrt': {
						'bad': new Array(),
						'good': new Array(),
					},
				},
				'kioskCounts': {},
			};
			const mapMarkers = _.toArray(stateMapMarkers);
			mapMarkers.forEach((marker) => {
				if (filterListOfKiosks.allKiosks.ids.includes(marker.id)) {
					finalListOfKiosks.markers.act.push(marker);
				}
				if (filterListOfKiosks.goodKiosks.ids.includes(marker.id)) {
					finalListOfKiosks.markers.chk.good.push(marker);
				}
				if (filterListOfKiosks.badKiosks.ids.includes(marker.id)) {
					finalListOfKiosks.markers.chk.bad.push(marker);
				}
				if (filterListOfKiosks.pwaGoodKiosks.ids.includes(marker.id)) {
					finalListOfKiosks.markers.pwa.good.push(marker);
				}
				if (filterListOfKiosks.pwaBadKiosks.ids.includes(marker.id)) {
					finalListOfKiosks.markers.pwa.bad.push(marker);
				}
				if (filterListOfKiosks.cefGoodKiosks.ids.includes(marker.id)) {
					finalListOfKiosks.markers.cef.good.push(marker);
				}
				if (filterListOfKiosks.cefBadKiosks.ids.includes(marker.id)) {
					finalListOfKiosks.markers.cef.bad.push(marker);
				}
				if (filterListOfKiosks.resGoodKiosks.ids.includes(marker.id)) {
					finalListOfKiosks.markers.res.good.push(marker);
				}
				if (filterListOfKiosks.resBadKiosks.ids.includes(marker.id)) {
					finalListOfKiosks.markers.res.bad.push(marker);
				}
				if (filterListOfKiosks.hozGoodKiosks.ids.includes(marker.id)) {
					finalListOfKiosks.markers.hoz.good.push(marker);
				}
				if (filterListOfKiosks.hozBadKiosks.ids.includes(marker.id)) {
					finalListOfKiosks.markers.hoz.bad.push(marker);
				}
				if (filterListOfKiosks.vrtGoodKiosks.ids.includes(marker.id)) {
					finalListOfKiosks.markers.vrt.good.push(marker);
				}
				if (filterListOfKiosks.vrtBadKiosks.ids.includes(marker.id)) {
					finalListOfKiosks.markers.vrt.bad.push(marker);
				}
			});
			finalListOfKiosks.kioskCounts = otherHelpers.buildList.getKioskCounts(finalListOfKiosks);
			console.log(consoleLogTitle + '[otherHelpers][buildList][kiosk] - filterList used to build:', filterListOfKiosks);
			console.log(consoleLogTitle + '[otherHelpers][buildList][kiosk] - finalList to return:', finalListOfKiosks);

			return finalListOfKiosks;
		},
	},
};

const OtherDashState = {
	namespaced: true,
	state: {
		analyticsReady: false,
		analyticsData: {},
		analyticsDataReady: false,
		isAnalyticsDataDirty: false,
		analyticsLoading: false,
		/**
		 * Begin state values for the Telemetry Summary Dashboard
		 */
		telemetryUpdateTime: false,
		telemetryReady: false,
		telemetryData: {},
		telemetryDataReady: false,
		telemetryDefaultsBase: {
			'base': {
				'classes': {
					successClasses: ['success'],
					warningClasses: ['warning'],
					criticalClasses: ['danger'],
				},
				'threshholds': {
					'default': {
						successThreshhold: 90,
						criticalThreshhold: 20,
					},
					'resolution': {
						successThreshhold: 95,
						criticalThreshhold: 50,
					},
				},
			},
			'defaults': {
				'active-kiosks': {
					'version': 0,
				},
				'pwa-version': {
					'version': '3.1.1',
				},
				'cef-version': {
					'version': '1.2.8',
				},
				'horizontal-resolution': {
					'resolution': '1920x1080',
				},
				'vertical-resolution': {
					'resolution': '1080x1920',
				},
			}
		},
		telemetryDefaultsData: {},
		telemetryCustomerData: {},
		telemetryDetailedData: {},
		telemetryMapDefaultMarkers: [],
		telemetryMapUpdatedMarkers: [],
		telemetryMapInfoWindows: {},
		telemetryLoading: false,
		isTelemetryDataDirty: false,
		/**
		 * END state values for the Telemetry Summary Dashboard
		 */
		/**
		 * Begin state values for the Configure Summary Dashboard
		 */
		configureUpdateTime: false,
		configureReady: false,
		configureData: {},
		configureDataReady: false,
		isConfigureDataDirty: false,
		configureLoading: false,
		/**
		 * END state values for the Configure Summary Dashboard
		 */
		checkedRankedPages: [
			'Explore VW',
			'Charging Stations',
			'Electrify America',
			'Electrify Home',
			'Accessory Builder',
			'Explore Dealer',
			'Know Your VW',
			'ID.4 Walkaround',
			'Media Videos',
			'Social Media',
		],
	},
	getters: {
		checkedRankedPages(state) {
			return state.checkedRankedPages;
		},
		analyticsData(state) {
			return state.analyticsData;
		},
		analyticsDataReady(state) {
			return state.analyticsDataReady;
		},
		analyticsLoading(state) {
			return state.analyticsLoading;
		},
		telemetryReady(state) {
			return state.telemetryReady;
		},
		telemetryData(state) {
			return state.telemetryData;
		},
		telemetryDataReady(state) {
			return state.telemetryDataReady;
		},
		telemetryLoading(state) {
			return state.telemetryLoading;
		},
	},
	actions: {
		async updateAnalytics({ commit, dispatch, state }) {
			const functionLogTitle = consoleLogTitle + '[actions][updateAnalytics]: '
			console.log(functionLogTitle + 'Executing function and building query parameters from state filters...');
			const fillEmptyDate60DayDefault = () => {
				const end = new Date();
				end.setTime(end.getTime() - 3600 * 1000 * 24 * 2);
				const start = new Date();
				start.setTime(start.getTime() - 3600 * 1000 * 24 * 62);
				const earliestStart = new Date('2023-01-26');
				earliestStart.setTime(earliestStart.getTime()); // - 3600 * 1000 * 24 * 1);
				let calcStart = start;
				if (start < earliestStart) {
					calcStart = earliestStart;
				}
				let formedStart = new Date(calcStart.toLocaleDateString('en-US').replace(/\//g, '-') + ' 00:00:00');
				let formedEnd = new Date(end.toLocaleDateString('en-US').replace(/\//g, '-') + ' 23:59:59');
				return [formedStart, formedEnd];
			};
			console.log(functionLogTitle + 'Check if date filtering parameter is valid...');
			let dateFilterIsGood = false;
			if (Array.isArray(state.filterDatesSelected)) {
				if (state.filterDatesSelected.length === 2) {
					console.log(functionLogTitle + 'Date filtering parameter is valid, isArray and has length of 2 items... Current value:', state.filterDatesSelected);
					dateFilterIsGood = true;
				}
			}
			if (dateFilterIsGood !== true) {
				console.log(functionLogTitle + 'Date filtering parameter is not valid, either not array or has length of less than 2 items... Current value:', state.filterDatesSelected);
				const fillEmptyDateFilter = fillEmptyDate60DayDefault();
				console.log(functionLogTitle + 'Setting date filtering parameter to last 60 days, to increase performance, instead of all available dates... New value:', fillEmptyDateFilter);
				commit(SET_FILTER_DATES_SELECTED, fillEmptyDateFilter);
			}

			commit(SET_ANALYTICS_DATA_READY, false);
			commit(SET_TELEMETRY_DATA_READY, false);
			commit(SET_LOADING, true);

			let filteredByDate = false;
			let dateFilters = state.filterDatesSelected;
			if (dateFilters.length === 2) {
				filteredByDate = true;
			}
			const accountFilters = state.filterAccountsSelected;

			const builtQueryParams = {
				accounts: accountFilters,
				pages: state.checkedRankedPages,
			};

			if (filteredByDate === true) {
				builtQueryParams.dateStart = dateFilters[0].toLocaleDateString('en-US').replace(/\//g, '-');
				builtQueryParams.dateEnd = dateFilters[1].toLocaleDateString('en-US').replace(/\//g, '-');
			}
			console.log(functionLogTitle + 'Firing AJAX call to retrieve Analytics...');
			console.log(functionLogTitle + '(filteredByDate) variable for AJAX: ' + filteredByDate);
			console.log(functionLogTitle + '(builtQueryParams) variable for AJAX:', builtQueryParams);
			if (filteredByDate === true) {
				console.log(functionLogTitle + '(filteredByDate) is true, additional parameters passed...' + filteredByDate);
				console.log(functionLogTitle + '(builtQueryParams.dateStart) variable for AJAX: ' + builtQueryParams.dateStart);
				console.log(functionLogTitle + '(builtQueryParams.dateEnd) variable for AJAX: ' + builtQueryParams.dateEnd);
			}
			console.log(functionLogTitle + '(builtQueryParams.accounts) variable for AJAX:', builtQueryParams.accounts);
			console.log(functionLogTitle + '(builtQueryParams.pages) variable for AJAX:', builtQueryParams.pages);
			await Analytics.retrieve(filteredByDate, builtQueryParams)
				.then(items => {
					console.log(functionLogTitle + 'Received response for AJAX call to retrieve analytics, (items) returned:', items);
					dispatch('updateAnalyticsStoreData', items);
				}).catch((error) => {
					console.error(functionLogTitle + 'Encountered AJAX ERROR for call to retrieve analytics...');
					console.error(functionLogTitle + '(error) response returned:', error);
				})
			commit(SET_IS_ANALYTICS_DATA_DIRTY, false);
			commit(SET_LOADING, false);
			commit(SET_ANALYTICS_DATA_READY, true);
		},
		async updateAnalyticsStoreData({ commit }, items) {
			if (items) {
				if (typeof items === 'object' || items instanceof Object) {
					commit(SET_ANALYTICS_DATA, items);
					if (items.telemetryData) {
						commit(SET_TELEMETRY_DATA, items.telemetryData);
						commit(SET_TELEMETRY_DATA_READY, true);
					}
				}
			}
		},
		async updateTelemetry({ commit, dispatch, state }) {
			const functionLogTitle = consoleLogTitle + '[actions][updateTelemetry]: '
			console.log(functionLogTitle + 'Attempting action, verify that action is eligible to execute...');
			const stLoading = state.telemetryLoading;
			const stReady = state.telemetryReady;
			const stDataReady = state.telemetryDataReady;
			console.log(functionLogTitle + 'Current value of [state.telemetryLoading]: ' + stLoading);
			console.log(functionLogTitle + 'Current value of [state.telemetryDataReady]: ' + stDataReady);
			console.log(functionLogTitle + 'Current value of [state.telemetryReady]: ' + stReady);
			let canUpdate = true;
			if (state.telemetryLoading === true) { // || state.telemetryReady === false || state.telemetryDataReady === false) {
				console.log(functionLogTitle + 'First test failed, bypassing running the telemetry update...');
				canUpdate = false
			}

			const stUpdateTime = state.telemetryUpdateTime;
			console.log(functionLogTitle + 'Current value of [state.telemetryUpdateTime]: ' + stUpdateTime);
			if (stUpdateTime !== false) {
				const testTimestamp = otherHelpers.isTimestamp(stUpdateTime);
				console.log(functionLogTitle + 'Is [state.telemetryUpdateTime] value a valid timestamp: ' + testTimestamp);
				if (testTimestamp === true) {
					const d1 = new Date(stUpdateTime);
					console.log(functionLogTitle + 'Parsed [stUpdateTime] to date: ' + d1.toLocaleString());
					const d2 = new Date();
					console.log(functionLogTitle + 'Current date after [stUpdateTime]: ' + d2.toLocaleString());
					const diffMinutes = otherHelpers.dateDiff.inMinutes(d1, d2);
					console.log(functionLogTitle + 'Is it less than 10 minutes since last update, [diffMinutes] value for dates: ' + diffMinutes);
					if (diffMinutes < 1) {
						canUpdate = false;
						commit(SET_TELEMETRY_READY, false);
						commit(SET_IS_TELEMETRY_DATA_DIRTY, false);
						commit(SET_TELEMETRY_READY, true);
					}
				}
			}

			if (canUpdate === true) {
				const updateTimestamp = new Date().getTime();
				commit(SET_TELEMETRY_UPDATE_TIME, updateTimestamp);
				commit(SET_TELEMETRY_READY, false);
				commit(SET_TELEMETRY_DATA_READY, false);
				commit(SET_TELEMETRY_LOADING, true);

				const filteredByDate = false;
				const builtQueryParams = {
					pages: state.checkedRankedPages,
				};

				console.log(functionLogTitle + 'Firing AJAX call to retrieve Telemetry...');
				console.log(functionLogTitle + '(filteredByDate) variable for AJAX: ' + filteredByDate);
				console.log(functionLogTitle + '(builtQueryParams) variable for AJAX:', builtQueryParams);
				console.log(functionLogTitle + '(builtQueryParams.pages) variable for AJAX:', builtQueryParams.pages);
				await Telemetry.retrieve(filteredByDate, builtQueryParams)
					.then(items => {
						console.log(functionLogTitle + 'Received response for AJAX call to retrieve telemetry, (items) returned:', items);
						dispatch('updateTelemetryStoreData', items);
					}).catch((error) => {
						console.error(functionLogTitle + 'Encountered AJAX ERROR for call to retrieve telemetry...');
						console.error(functionLogTitle + '(error) response returned:', error);
					})
				commit(SET_TELEMETRY_LOADING, false);
				commit(SET_TELEMETRY_DATA_READY, true);
				commit(SET_IS_TELEMETRY_DATA_DIRTY, false);
				commit(SET_TELEMETRY_READY, true);
			}
		},
		async updateTelemetryStoreData({ commit }, items) {
			if (items) {
				if (typeof items === 'object' || items instanceof Object) {
					commit(SET_TELEMETRY_DATA, items);
					if (items?.teleDefaults !== undefined) {
						commit(SET_TELE_DEFAULTS_DATA, items.teleDefaults);
					}
					if (items?.kioskDetails !== undefined) {
						commit(SET_TELE_CUSTOMER_DATA, items.kioskDetails);
					}
					if (items?.mapMarkers !== undefined) {
						commit(SET_TELE_MAP_DEFAULT_MARKERS, items.mapMarkers);
						commit(SET_TELE_MAP_UPDATED_MARKERS, items.mapMarkers);
					}
					if (items?.infoWindows !== undefined) {
						commit(SET_TELE_MAP_INFO_WINDOWS, items.infoWindows);
					}
					commit(SET_TELE_DETAILED_DATA, items);
				}
			}
		},
		async setIsTelemetryDataDirty({ commit }, isDirty) {
			const functionLogTitle = consoleLogTitle + '[actions][setIsTelemetryDataDirty] - ';
			console.log(functionLogTitle + 'Passed (isDirty): typeof: ' + typeof isDirty + ' - value: ' + isDirty);
			if (isDirty === true || isDirty === false) {
				commit(SET_IS_TELEMETRY_DATA_DIRTY, isDirty);
			}
		},
		async updateConfigure({ commit, dispatch, state }) {
			const functionLogTitle = consoleLogTitle + '[actions][updateConfigure]: '
			console.log(functionLogTitle + 'Attempting action, verify that action is eligible to execute...');
			const stLoading = state.configureLoading;
			const stReady = state.configureReady;
			const stDataReady = state.configureDataReady;
			console.log(functionLogTitle + 'Current value of [state.configureLoading]: ' + stLoading);
			console.log(functionLogTitle + 'Current value of [state.configureDataReady]: ' + stDataReady);
			console.log(functionLogTitle + 'Current value of [state.configureReady]: ' + stReady);
			let canUpdate = true;
			if (state.configureLoading === true) { // || state.configureReady === false || state.configureDataReady === false) {
				console.log(functionLogTitle + 'First test failed, bypassing running the configure update...');
				canUpdate = false
			}

			const stUpdateTime = state.configureUpdateTime;
			console.log(functionLogTitle + 'Current value of [state.configureUpdateTime]: ' + stUpdateTime);
			if (stUpdateTime !== false) {
				const testTimestamp = otherHelpers.isTimestamp(stUpdateTime);
				console.log(functionLogTitle + 'Is [state.configureUpdateTime] value a valid timestamp: ' + testTimestamp);
				if (testTimestamp === true) {
					const d1 = new Date(stUpdateTime);
					console.log(functionLogTitle + 'Parsed [stUpdateTime] to date: ' + d1.toLocaleString());
					const d2 = new Date();
					console.log(functionLogTitle + 'Current date after [stUpdateTime]: ' + d2.toLocaleString());
					const diffMinutes = otherHelpers.dateDiff.inMinutes(d1, d2);
					console.log(functionLogTitle + 'Is it less than 10 minutes since last update, [diffMinutes] value for dates: ' + diffMinutes);
					if (diffMinutes < 1) {
						canUpdate = false;
						commit(SET_CONFIGURE_READY, false);
						commit(SET_IS_CONFIGURE_DATA_DIRTY, false);
						commit(SET_CONFIGURE_READY, true);
					}
				}
			}

			if (canUpdate === true) {
				const updateTimestamp = new Date().getTime();
				commit(SET_CONFIGURE_UPDATE_TIME, updateTimestamp);
				commit(SET_CONFIGURE_READY, false);
				commit(SET_CONFIGURE_DATA_READY, false);
				commit(SET_CONFIGURE_LOADING, true);

				const filteredByDate = false;
				const builtQueryParams = {
					pages: state.checkedRankedPages,
				};

				console.log(functionLogTitle + 'Firing AJAX call to retrieve Configure...');
				console.log(functionLogTitle + '(filteredByDate) variable for AJAX: ' + filteredByDate);
				console.log(functionLogTitle + '(builtQueryParams) variable for AJAX:', builtQueryParams);
				console.log(functionLogTitle + '(builtQueryParams.pages) variable for AJAX:', builtQueryParams.pages);
				await Configure.retrieve(filteredByDate, builtQueryParams)
					.then(items => {
						console.log(functionLogTitle + 'Received response for AJAX call to retrieve configure, (items) returned:', items);
						dispatch('updateConfigureStoreData', items);
					}).catch((error) => {
						console.error(functionLogTitle + 'Encountered AJAX ERROR for call to retrieve configure...');
						console.error(functionLogTitle + '(error) response returned:', error);
					})
				commit(SET_CONFIGURE_LOADING, false);
				commit(SET_CONFIGURE_DATA_READY, true);
				commit(SET_IS_CONFIGURE_DATA_DIRTY, false);
				commit(SET_CONFIGURE_READY, true);
			}
		},
		async updateConfigureStoreData({ commit }, items) {
			if (items) {
				if (typeof items === 'object' || items instanceof Object) {
					commit(SET_CONFIGURE_DATA, items);
				}
			}
		},
		async setIsConfigureDataDirty({ commit }, isDirty) {
			const functionLogTitle = consoleLogTitle + '[actions][setIsConfigureDataDirty] - ';
			console.log(functionLogTitle + 'Passed (isDirty): typeof: ' + typeof isDirty + ' - value: ' + isDirty);
			if (isDirty === true || isDirty === false) {
				commit(SET_IS_CONFIGURE_DATA_DIRTY, isDirty);
			}
		},
		async setIsAnalyticsDataDirty({ commit }, filterPayload) {
			commit(SET_IS_ANALYTICS_DATA_DIRTY, filterPayload);
		},
	},
	mutations: {
		[SET_ANALYTICS_READY](state, isReady) {
			if (isReady === true || isReady === false) {
				state.analyticsReady = isReady;
			}
		},
		[SET_IS_ANALYTICS_DATA_DIRTY](state, isDirty) {
			if (isDirty === true || isDirty === false) {
				state.isAnalyticsDataDirty = isDirty;
			}
		},
		[SET_ANALYTICS_DATA](state, items) {
			const functionLogTitle = consoleLogTitle + 'mutations.[SET_ANALYTICS_DATA] - ';
			console.log(functionLogTitle + 'Passed (items):', items);
			const stAnalyticsData = state.analyticsData;
			console.log(functionLogTitle + 'Current (state.analyticsData):', stAnalyticsData);
			const jsonAnalytics = JSON.parse(JSON.stringify(items));
			let builtAnalytics = {};
			for (const [jsonKey, jsonData] of Object.entries(jsonAnalytics)) {
				builtAnalytics[jsonKey] = jsonData;
			}
			console.log(functionLogTitle + 'Final (builtAnalytics):', builtAnalytics);
			state.analyticsData = builtAnalytics;
		},
		[SET_ANALYTICS_DATA_READY](state, isReady) {
			if (isReady === true || isReady === false) {
				state.analyticsDataReady = isReady;
			}
		},
		[SET_TELEMETRY_UPDATE_TIME](state, timestamp) {
			state.telemetryUpdateTime = timestamp;
		},
		[SET_TELEMETRY_READY](state, isReady) {
			if (isReady === true || isReady === false) {
				state.telemetryReady = isReady;
			}
		},
		[SET_TELEMETRY_DATA](state, items) {
			const functionLogTitle = consoleLogTitle + '[mutations][SET_TELEMETRY_DATA] - ';
			console.log(functionLogTitle + 'Passed (items):', items);
			const stTelemetryData = state.telemetryData;
			console.log(functionLogTitle + 'Current (state.telemetryData):', stTelemetryData);
			const jsonTelemetry = JSON.parse(JSON.stringify(items));
			let builtTelemetry = {};
			for (const [jsonKey, jsonData] of Object.entries(jsonTelemetry)) {
				builtTelemetry[jsonKey] = jsonData;
			}
			console.log(functionLogTitle + 'Final (builtTelemetry):', builtTelemetry);
			state.telemetryData = builtTelemetry;
		},
		[SET_TELEMETRY_DATA_READY](state, isReady) {
			if (isReady === true || isReady === false) {
				state.telemetryDataReady = isReady;
			}
		},
		[SET_TELE_DEFAULTS_DATA](state, items) {
			const functionLogTitle = consoleLogTitle + '[mutations][SET_TELE_DEFAULTS_DATA] - ';
			console.log(functionLogTitle + 'Passed (items):', items);
			const dbDataDefaults = JSON.parse(JSON.stringify(items));
			const stTelemetryBase = state.telemetryDefaultsBase;
			const stBaseDefaults = stTelemetryBase.base;
			const stDataDefaults = stTelemetryBase.defaults;
			const stDataKeys = Object.keys(stDataDefaults);
			const finalDefaults = {};
			stDataKeys.forEach((dataKey) => {
				let threshKey = 'default';
				let valuesKey = 'version';
				if (dataKey.indexOf('resolution') !== -1) {
					threshKey = 'resolution';
					valuesKey = 'resolution';
				}
				let tempDefault = {
					[valuesKey]: stDataDefaults[dataKey][valuesKey],
					successThreshhold: stBaseDefaults['threshholds'][threshKey]['successThreshhold'],
					criticalThreshhold: stBaseDefaults['threshholds'][threshKey]['criticalThreshhold'],
				};
				let successClasses = stBaseDefaults['classes'].successClasses;
				let warningClasses = stBaseDefaults['classes'].warningClasses;
				let criticalClasses = stBaseDefaults['classes'].criticalClasses;

				let dbDefault = false;
				if (Boolean(dbDataDefaults[dataKey]) !== false) {
					dbDefault = JSON.parse(JSON.stringify(dbDataDefaults[dataKey]));
					if (Boolean(dbDefault) !== false) {
						if (dbDefault?.default_value !== undefined) {
							tempDefault[valuesKey] = dbDefault.default_value;
						}
						if (dbDefault?.threshhold_success !== undefined) {
							tempDefault.successThreshhold = dbDefault.threshhold_success;
						}
						if (dbDefault?.threshhold_critical !== undefined) {
							tempDefault.criticalThreshhold = dbDefault.threshhold_critical;
						}
						if (dbDefault?.success_add_css_classes !== undefined) {
							const dbSuccessClasses = otherHelpers.defaultsClassArray(dbDefault.success_add_css_classes);
							if (dbSuccessClasses !== false) {
								dbSuccessClasses.forEach(classItem => {
									successClasses.push(classItem);
								});
							}
						}
						if (dbDefault?.warning_add_css_classes !== undefined) {
							const dbWarningClasses = otherHelpers.defaultsClassArray(dbDefault.warning_add_css_classes);
							if (dbWarningClasses !== false) {
								dbWarningClasses.forEach(classItem => {
									warningClasses.push(classItem);
								});
							}
						}
						if (dbDefault?.critical_add_css_classes !== undefined) {
							const dbCriticalClasses = otherHelpers.defaultsClassArray(dbDefault.critical_add_css_classes);
							if (dbCriticalClasses !== false) {
								dbCriticalClasses.forEach(classItem => {
									criticalClasses.push(classItem);
								});
							}
						}
					}
				}
				tempDefault.successClasses = successClasses.join(' ');
				tempDefault.warningClasses = warningClasses.join(' ');
				tempDefault.criticalClasses = criticalClasses.join(' ');
				console.log(functionLogTitle + 'Built [' + dataKey + '] - (tempDefault) values:', tempDefault);
				finalDefaults[dataKey] = tempDefault;
			});
			console.log(functionLogTitle + 'Built (finalDefaults) values:', finalDefaults);
			state.telemetryDefaultsData = finalDefaults;
		},
		[SET_TELE_CUSTOMER_DATA](state, items) {
			state.telemetryCustomerData = items;
		},
		[SET_TELE_MAP_DEFAULT_MARKERS](state, items) {
			state.telemetryMapDefaultMarkers = items;
		},
		[SET_TELE_MAP_UPDATED_MARKERS](state, items) {
			state.telemetryMapUpdatedMarkers = items;
		},
		[SET_TELE_MAP_INFO_WINDOWS](state, items) {
			state.telemetryMapInfoWindows = items;
		},
		[SET_TELE_DETAILED_DATA](state, items) {
			const functionLogTitle = consoleLogTitle + '[mutations][SET_TELE_DETAILED_DATA] - ';
			console.log(functionLogTitle + 'Passed (items):', items);
			const stDefaults = state.telemetryDefaultsData;
			console.log(functionLogTitle + 'Current (state.defaults):', stDefaults);
			const stKiosks = state.telemetryCustomerData;
			console.log(functionLogTitle + 'Current (state.customers):', stKiosks);
			const stMarkers = state.telemetryMapDefaultMarkers;
			console.log(functionLogTitle + 'Current (state.markers):', stMarkers);
			const detailData = otherHelpers.buildList.kiosk(stKiosks, stDefaults, stMarkers);
			console.log(functionLogTitle + 'BUILT (detailData):', detailData);
			state.telemetryDetailedData = detailData;
		},
		[SET_TELEMETRY_LOADING](state, isLoading) {
			if (isLoading === true || isLoading === false) {
				state.telemetryLoading = isLoading;
			}
		},
		[SET_IS_TELEMETRY_DATA_DIRTY](state, isDirty) {
			state.isTelemetryDataDirty = isDirty;
		},
		[SET_CONFIGURE_UPDATE_TIME](state, timestamp) {
			state.configureUpdateTime = timestamp;
		},
		[SET_CONFIGURE_READY](state, isReady) {
			if (isReady === true || isReady === false) {
				state.configureReady = isReady;
			}
		},
		[SET_CONFIGURE_DATA](state, items) {
			const functionLogTitle = consoleLogTitle + '[mutations][SET_CONFIGURE_DATA] - ';
			console.log(functionLogTitle + 'Passed (items):', items);
			const jsonConfigure = JSON.parse(JSON.stringify(items));
			const builtConfigure = {};
			if (typeof jsonConfigure === 'object' || jsonConfigure instanceof Object) { //Array.isArray(jsonConfigure)) {
				console.log(functionLogTitle + '(jsonConfigure) is /object/: typeof: ' + typeof jsonConfigure);
				if (jsonConfigure) {
					Object.keys(jsonConfigure).map((key) => { //for (const [key, configItem] of Object.entries(jsonConfigure)) {
						const tempConfig = { ...jsonConfigure[key] };
						if (otherHelpers.testJSON(tempConfig.payload)) {
							const workPayload = JSON.parse(tempConfig.payload);
							tempConfig.payload = workPayload;
						}
						if (otherHelpers.testJSON(tempConfig.prior_configs)) {
							const workPriors = JSON.parse(tempConfig.prior_configs);
							tempConfig.prior_configs = workPriors;
						}
						if (otherHelpers.testJSON(tempConfig.version_detail)) {
							const workVersion = JSON.parse(tempConfig.version_detail);
							tempConfig.version_detail = workVersion;
						}
						builtConfigure[key] = tempConfig;
					});
				}
			}
			console.log(functionLogTitle + 'Final (builtConfigure):', builtConfigure);
			state.configureData = builtConfigure;
		},
		[SET_CONFIGURE_DATA_READY](state, isReady) {
			if (isReady === true || isReady === false) {
				state.configureDataReady = isReady;
			}
		},
		[SET_CONFIGURE_LOADING](state, isLoading) {
			if (isLoading === true || isLoading === false) {
				state.configureLoading = isLoading;
			}
		},
		[SET_IS_CONFIGURE_DATA_DIRTY](state, isDirty) {
			state.isConfigureDataDirty = isDirty;
		},
	}
}

export default OtherDashState;
